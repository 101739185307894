import styled from 'styled-components'
import { defaultTheme } from '@compeon-os/components'

const SubmitContainer = styled.div`
  text-align: right;

  button {
    width: 100%;

    @media (min-width: ${props => props.theme.breakpointMobile}) {
      width: auto;
    }

    &:not(:last-child) {
      margin-bottom: 10px;

      @media (min-width: ${props => props.theme.breakpointMobile}) {
        margin-bottom: 0;
        margin-right: 10px;
      }
    }
  }
`

SubmitContainer.defaultProps = { theme: defaultTheme }

export default SubmitContainer

import React, { useState, useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { defaultTheme, Header, Spinner } from '@compeon-os/components'
import { ThemeProvider } from 'styled-components'

import { Layout, PageContent } from 'components/Layout'
import { LinkProvider } from 'components/LinkContext'
import GlobalStyles from 'components/GlobalStyles'
import { Finish, Register, Start } from 'pages'
import ErrorScreen from 'components/ErrorScreen'

const { REACT_APP_CMS_BASE_URL: CMS_BASE_URL } = process.env

export function App() {
  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={defaultTheme}>
        <Layout>
          <Header hideUserInformation />
          <PageContent>
            <Switch>
              <Route component={Start} path="/start" exact />
              <Route component={Finish} path="/finish" exact />
              <Route component={Register} path="/register" exact />
              <Route component={ErrorScreen} path="*" />
            </Switch>
          </PageContent>
        </Layout>
      </ThemeProvider>
    </>
  )
}

function AppWithRouterAndTheme() {
  const [theme, setTheme] = useState(null)

  useEffect(() => {
    // Mock theme for now.

    setTimeout(() => {
      setTheme({
        links: {
          home: CMS_BASE_URL,
          imprint: `${CMS_BASE_URL}/impressum`,
          privacy: `${CMS_BASE_URL}/datenschutz`,
          termsAndConditions: `${CMS_BASE_URL}/agb`,
        },
      })
    }, 400)
  }, [])

  if (!theme) return <Spinner isLoading />

  return (
    <LinkProvider value={theme.links}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </LinkProvider>
  )
}

export default AppWithRouterAndTheme

import styled from 'styled-components'
import { defaultTheme } from '@compeon-os/components'

const Description = styled.div`
  color: ${props => props.theme.secondaryTextColor};
  font-size: 18px;
  margin-bottom: 40px;
`

Description.defaultProps = { theme: defaultTheme }

export default Description

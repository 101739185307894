import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from '@compeon-os/translated-components'

import Description from 'components/Description'
import EmailField from 'components/EmailField'
import RouteContainer from 'components/RouteContainer'
import parseRedirectUriFromQueryString from 'utils/parseRedirectUriFromQueryString'
import validateEmail from 'utils/validateEmail'
import { checkEmailAvailability } from 'api'

import { redirectToLogin } from './modules/start'
import translations from './Start.translations'

function Start({ history, location, translate }) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [email, setEmail] = useState('')
  const [redirectUri] = useState(
    parseRedirectUriFromQueryString(location.search)
  )

  function handleEmailTaken() {
    redirectToLogin(redirectUri.toString())
  }

  function handleEmailAvailable() {
    history.push('/register', { email, redirectUri: redirectUri.toString() })
  }

  function handleError() {
    setError(translate('errors.unknown'))
  }

  const handleChange = event => {
    const email = event.target.value.trim().toLowerCase()

    setEmail(email)
    setError(null)
  }

  const handleSubmit = async email => {
    if (!validateEmail(email)) {
      setError(translate('errors.invalid'))
      return
    }

    setLoading(true)

    try {
      await checkEmailAvailability(email)
    } catch ({ response }) {
      if (response.status === 404) {
        handleEmailAvailable(response)
      } else {
        handleError(response)
        setLoading(false)
      }

      return
    }

    handleEmailTaken()
  }

  if (!redirectUri) return '400'

  return (
    <RouteContainer condensed title={translate('title')}>
      <Description>{translate('description1')}</Description>
      <Description>{translate('description2')}</Description>
      <EmailField
        disabled={loading}
        error={error}
        onChange={handleChange}
        onSubmit={handleSubmit}
        value={email}
      />
    </RouteContainer>
  )
}

Start.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  location: PropTypes.object,
  translate: PropTypes.func.isRequired,
}

export default withTranslation({ translations })(Start)

const translations = {
  de_DE: {
    errors: {
      missing: {
        email: 'Bitte geben Sie Ihre E-Mail-Adresse an',
        firstName: 'Bitte geben Sie Ihren Vornamen an',
        lastName: 'Bitte geben Sie Ihren Nachnamen an',
        salutation: 'Bitte wählen Sie eine Anrede',
        telephone: 'Bitte geben Sie eine Telefonnummer an',
        termsAndConditionsAccepted:
          'Zum Fortfahren müssen sie den AGB und Datenschutzbestimmungen zustimmen',
      },
      invalid: {
        email: 'Bitte geben Sie eine gültige E-Mail-Adresse an',
        telephone:
          'Eine Telefonnummer muss mit "0" oder "+" beginnen und mindestens sechs Zeichen beinhalten',
        additionalTelephone:
          'Eine Telefonnummer muss mit "0" oder "+" beginnen und mindestens sechs Zeichen beinhalten',
      },
    },
    user: {
      attrs: {
        additionalTelephone: 'Weitere Telefonnummer',
        email: 'E-Mail-Adresse',
        firstName: 'Vorname',
        lastName: 'Nachname',
        multiplierKind: 'Für wen werden Sie vorwiegend COMPEON nutzen?',
        newsletterRecipient:
          'Erhalten Sie monatlich wertvolle Finanzierungs-Tipps und Informationen',
        salutation: 'Anrede',
        telephone: 'Telefonnummer',
        termsAndConditionsAccepted:
          'Ich habe die AGB und Datenschutzbestimmungen der COMPEON GmbH gelesen und akzeptiert. Ich bestätige die Richtigkeit meiner Angaben.',
      },
      enums: {
        multiplierKind: {
          agency: 'Für meine(n) Mandanten',
          none: 'Für mein Unternehmen',
          vendor: 'Für meine(n) Kunden',
        },
        salutation: {
          mr: 'Herr',
          ms: 'Frau',
        },
      },
      termsAndConditions: 'AGB',
      privacy: 'Datenschutzbestimmungen',
    },
    submit: 'Jetzt registrieren',
  },
}

export default translations

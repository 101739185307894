const translations = {
  de_DE: {
    description1: `
      Willkommen beim Finanzportal COMPEON. Sie wurden eingeladen, sich als
      Nutzer für einen bereits bestehenden Unternehmensaccount zu registrieren.
    `,
    description2: `
      Geben Sie hier einfach Ihre E-Mail-Adresse ein, um mit der
      Registrierung zu beginnen. Es dauert maximal 2 Minuten.
    `,
    errors: {
      invalid: 'Bitte geben Sie eine gültige Email-Adresse ein.',
      unknown:
        'Es ist ein unbekannter Fehler aufgetreten. Bitte prüfen Sie Ihre Internetverbindung.',
    },
    title: 'Registrierung',
  },
}

export default translations

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { defaultTheme } from '@compeon-os/components'
import { withTranslation } from '@compeon-os/translated-components'

import translations from './FooterContent.translations'

const {
  REACT_APP_IMPRINT_URL: IMPRINT_URL,
  REACT_APP_PRIVACY_URL: PRIVACY_URL,
  REACT_APP_TAC_URL: TAC_URL,
} = process.env

const FooterLink = styled.a`
  display: block;
  color: ${props => props.theme.secondaryTextColor};
  text-transform: none;

  @media (min-width: ${props => props.theme.breakpointMobile}) {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 40px;
    }
  }
`

FooterLink.defaultProps = { theme: defaultTheme }

const FooterContent = ({ translate }) => (
  <>
    <FooterLink href={TAC_URL} target="_blank" rel="noopener noreferrer">
      {translate('termsAndConditions')}
    </FooterLink>
    <FooterLink href={PRIVACY_URL} target="_blank" rel="noopener noreferrer">
      {translate('privacy')}
    </FooterLink>
    <FooterLink href={IMPRINT_URL} target="_blank" rel="noopener noreferrer">
      {translate('imprint')}
    </FooterLink>
  </>
)

FooterContent.propTypes = {
  translate: PropTypes.func,
}

export default withTranslation({ translations })(FooterContent)

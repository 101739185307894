import PropTypes from 'prop-types'

import parseRedirectUriFromQueryString from 'utils/parseRedirectUriFromQueryString'

function Finish({ location }) {
  const redirectUri = parseRedirectUriFromQueryString(location.search)

  if (!redirectUri) return '400'

  window.location.href = redirectUri

  return null
}

Finish.propTypes = {
  location: PropTypes.object,
}

export default Finish

import { CLIENT_ID } from 'globalConstants'
import buildFinishUri from 'helpers/buildFinishUri'

const { REACT_APP_OAUTH_AUTHORIZATION_URL: OAUTH_AUTHORIZATION_URL } =
  process.env

export function redirectToLogin(redirectUri) {
  // TODO: Do we need a state here?

  const params = new URLSearchParams({
    client_id: CLIENT_ID,
    redirect_uri: buildFinishUri(redirectUri).toString(),
    response_type: 'code',
  })
  const uri = new URL(OAUTH_AUTHORIZATION_URL)

  uri.search = params.toString()
  window.location.href = uri.toString()
}

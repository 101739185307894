import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from '@compeon-os/translated-components'
import {
  defaultTheme,
  CompeonIcon,
  H2,
  H4,
  Link,
  Footer,
} from '@compeon-os/components'

import translations from './ErrorScreen.translations'

const Container = styled.div`
  align-items: center;
  background-attachment: fixed;
  background-image: url('https://cdn.compeon.de/layouts/images/forest.jpg');
  background-size: cover;
  color: hsl(0, 0%, 81%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 40px;
  position fixed;
  top: 0;
  width: 100%;

  > h2, > h4 {
    color: hsl(0, 0%, 81%);
  }

  > *:not(:last-child) {
    margin-bottom: 60px;

    @media (min-width: ${props => props.theme.breakpointMobile}) {
      margin-bottom: 140px;
    }
  }

  > footer {
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
  }
`

Container.defaultProps = { theme: defaultTheme }

const StyledLink = styled(Link)`
  color: hsl(0, 0%, 81%);

  &:hover {
    color: white;
  }
`

const StatusCode = styled.div`
  font-family: Montserrat;
  font-size: 65px;
  font-weight: 800;
  text-align: center;

  @media (min-width: ${props => props.theme.breakpointMobile}) {
    font-size: 150px;
  }

  svg {
    margin: 0 12px;
    margin-bottom: -4px;
    height: 50px;
    width: 50px;

    @media (min-width: ${props => props.theme.breakpointMobile}) {
      height: 110px;
      width: 110px;
    }
  }
`

StatusCode.defaultProps = { theme: defaultTheme }

const ErrorScreen = ({ translate }) => (
  <Container>
    <H2>{translate('headerMessage')}</H2>
    <StatusCode>
      4<CompeonIcon color="hsl(0, 0%, 81%)" />4
    </StatusCode>
    <H4>{translate('errorMessage')}</H4>

    <StyledLink href="https://www.compeon.de">
      {translate('linkLabel')}
    </StyledLink>
    <Footer style={{ marginTop: 'auto' }} />
  </Container>
)

ErrorScreen.propTypes = {
  translate: PropTypes.func,
}

export default withTranslation({ translations })(ErrorScreen)

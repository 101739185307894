function buildFinishUri(redirectUri) {
  const { REACT_APP_FINISH_URL: FINISH_URL } = process.env
  const params = new URLSearchParams({ redirect_uri: redirectUri })
  const uri = new URL(FINISH_URL)

  uri.search = params.toString()

  return uri
}

export default buildFinishUri

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash.get'
import { withTranslation } from '@compeon-os/translated-components'
import { Grid } from '@compeon-os/components'

import Description from 'components/Description'
import RegistrationForm from 'components/RegistrationForm'
import RouteContainer from 'components/RouteContainer'
import normalizeMultiplierKind from 'helpers/normalizeMultiplierKind'
import stripEmptyValues from 'utils/stripEmptyValues'
import { createUser } from 'api'

import translations from './Register.translations'
import {
  getValidationErrors,
  redirectToPasswordSetup,
} from './modules/register'

const HotSecretTip = styled.p`
  color: ${props => props.theme.secondaryTextColor};
  margin-bottom: 40px;
`

const SubmitError = styled.p`
  color: ${props => props.theme.inputErrorColor};
  margin-bottom: 40px;
`

function Register({ location, translate }) {
  const [errors, setErrors] = useState({})
  const [state, setState] = useState({ email: get(location, 'state.email') })
  const [redirectUri] = useState(get(location, 'state.redirectUri'))
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitError, setSubmitError] = useState(false)

  const handleChange = (key, value) => {
    setState({ ...state, [key]: value })
  }

  const handleSubmit = async () => {
    const errors = getValidationErrors(state)

    setErrors(errors)

    if (Object.keys(errors).length > 0) return

    setIsSubmitting(true)

    try {
      const user = await createUser({
        ...stripEmptyValues(state),
        multiplierKind: normalizeMultiplierKind(state.multiplierKind),
        termsAndConditionsAcceptedAt: new Date().toISOString(),
      })
      const passwordSetupUri = get(user, 'data.data.links.password-init-url')

      redirectToPasswordSetup({
        email: state.email,
        passwordSetupUri,
        redirectUri,
      })
    } catch (e) {
      setSubmitError(true)
      throw e
    } finally {
      // TODO: Check if polyfills cover finally in older browsers.
      setIsSubmitting(false)
    }
  }

  if (!state.email || !redirectUri) return '400'

  return (
    <RouteContainer title={translate('title')}>
      <Grid>
        <Description>{translate('description')}</Description>
        <HotSecretTip data-testid="hotSecretTip">
          <strong>{translate('hotSecretTipLabel')}</strong>
          {translate('hotSecretTip')}
        </HotSecretTip>
        <SubmitError>{submitError && translate('errors.submit')}</SubmitError>
      </Grid>
      <RegistrationForm
        errors={errors}
        isSubmitting={isSubmitting}
        onChange={handleChange}
        onSubmit={handleSubmit}
        values={state}
      />
    </RouteContainer>
  )
}

Register.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  location: PropTypes.object,
  translate: PropTypes.func.isRequired,
}

export default withTranslation({ translations })(Register)

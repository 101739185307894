import CompeonAPI from '@compeon-os/api-client'
import sha1 from 'crypto-js/sha1'

// API clients must be lazy loaded to not immediately trigger redirects to the
// auth service.

const { Email, User } = new CompeonAPI({
  baseURL: process.env.REACT_APP_API_URL,
  skipAuthentication: true,
})

export function checkEmailAvailability(email) {
  return Email.find({ id: sha1(email) })
}

export function createUser(user) {
  return User.create({ data: user })
}

import { createGlobalStyle } from 'styled-components'

const fontFaces = `
  @font-face {
    font-family: Montserrat;
    src:  url('https://cdn.compeon.de/layouts/fonts/montserrat-extralight-webfont.woff2') format('woff2'),
          url('https://cdn.compeon.de/layouts/fonts/montserrat-extralight-webfont.woff') format('woff');
    font-weight: 200;
  }

  @font-face {
    font-family: Montserrat;
    src:  url('https://cdn.compeon.de/layouts/fonts/montserrat-regular-webfont.woff2') format('woff2'),
          url('https://cdn.compeon.de/layouts/fonts/montserrat-regular-webfont.woff') format('woff');
    font-weight: normal;
  }

  @font-face {
    font-family: Montserrat;
    src:  url('https://cdn.compeon.de/layouts/fonts/montserrat-semibold-webfont.woff2') format('woff2'),
          url('https://cdn.compeon.de/layouts/fonts/montserrat-semibold-webfont.woff') format('woff');
    font-weight: bold;
  }

  @font-face {
    font-family: Montserrat;
    src:  url('https://cdn.compeon.de/layouts/fonts/montserrat-bold-webfont.woff2') format('woff2'),
          url('https://cdn.compeon.de/layouts/fonts/montserrat-bold-webfont.woff') format('woff');
    font-weight: 800;
  }
`

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    height: 100vh;
    margin: 0;
  }

  ${fontFaces}

  body, #root {
    background: #f5f5f5;
    height: 100%;
    margin: 0;

    font-family: 'Montserrat', montserrat, Arial, sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  body.ReactModal__Body--open {
    overflow-y: hidden;
  }

  a,
  a:hover,
  a:visited,
  a:active {
    color: #27718c;
  }

  a,
  a:visited {
    text-decoration: none;
    text-transform: uppercase;
  }
`

export default GlobalStyles

const VALID_MULTIPLIER_KINDS = {
  agency: 'agency',
  none: null,
  vendor: 'vendor',
}

function normalizeMultiplierKind(multiplierKind) {
  return VALID_MULTIPLIER_KINDS[multiplierKind]
}

export default normalizeMultiplierKind

import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from '@compeon-os/translated-components'
import {
  Button,
  Checkbox,
  Column,
  Grid,
  Row,
  Select,
  TextInput,
} from '@compeon-os/components'

import LinkContext from 'components/LinkContext'
import SubmitContainer from 'components/SubmitContainer'

import translations from './RegistrationForm.translations'

function RegistrationForm({
  errors,
  isSubmitting,
  onChange,
  onSubmit,
  translate,
  values,
}) {
  const links = useContext(LinkContext)
  const [submitted, setSubmitted] = useState(false)
  const salutationOptions = [
    { value: null, label: '' },
    { value: 'mr', label: translate('user.enums.salutation.mr') },
    { value: 'ms', label: translate('user.enums.salutation.ms') },
  ]

  const multiplierKindOptions = [
    { value: 'none', label: translate('user.enums.multiplierKind.none') },
    { value: 'agency', label: translate('user.enums.multiplierKind.agency') },
    { value: 'vendor', label: translate('user.enums.multiplierKind.vendor') },
  ]

  function formatTermsAndConditionsLabel() {
    const termsAndConditionsLink = (
      <a href={links.termsAndConditions}>
        {translate('user.termsAndConditions')}
      </a>
    )
    const privacyLink = <a href={links.privacy}>{translate('user.privacy')}</a>

    return (
      <>
        {translate('user.attrs.termsAndConditionsAccepted')} (
        {termsAndConditionsLink}, {privacyLink})
      </>
    )
  }

  function getHandleInputChange(key) {
    return event => onChange(key, event.target.value)
  }

  function getHandleCheckboxChange(key) {
    return checked => onChange(key, checked)
  }

  const handleSubmit = event => {
    event.stopPropagation()
    event.preventDefault()

    setSubmitted(true)
    onSubmit(values)
  }

  function translateError(attributeName) {
    if (!submitted) return ''
    if (!errors[attributeName]) return ''

    return translate(`errors.${errors[attributeName]}.${attributeName}`)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid>
        <Row>
          <Column size={{ xs: 5, sm: 3, lg: 2 }}>
            <label htmlFor="salutation">
              {translate('user.attrs.salutation')}
            </label>
            <Select
              error={translateError('salutation')}
              id="salutation"
              onChange={getHandleInputChange('salutation')}
              options={salutationOptions}
              value={values.salutation}
            />
          </Column>
          <Column size={{ xs: 12, sm: 4, lg: 4 }}>
            <label htmlFor="first-name">
              {translate('user.attrs.firstName')}
            </label>
            <TextInput
              error={translateError('firstName')}
              id="first-name"
              onChange={getHandleInputChange('firstName')}
              value={values.firstName || ''}
            />
          </Column>
          <Column size={{ xs: 12, sm: 5, lg: 6 }}>
            <label htmlFor="last-name">
              {translate('user.attrs.lastName')}
            </label>
            <TextInput
              error={translateError('lastName')}
              id="last-name"
              onChange={getHandleInputChange('lastName')}
              value={values.lastName || ''}
            />
          </Column>
        </Row>

        <Row>
          <Column size={{ xs: 12, sm: 6 }}>
            <label htmlFor="telephone">
              {translate('user.attrs.telephone')}
            </label>
            <TextInput
              error={translateError('telephone')}
              id="telephone"
              onChange={getHandleInputChange('telephone')}
              value={values.telephone || ''}
            />
          </Column>
          <Column size={{ xs: 12, sm: 6 }}>
            <label htmlFor="additional-telephone">
              {translate('user.attrs.additionalTelephone')}
            </label>
            <TextInput
              error={translateError('additionalTelephone')}
              id="additional-telephone"
              onChange={getHandleInputChange('additionalTelephone')}
              value={values.additionalTelephone || ''}
            />
          </Column>
        </Row>

        <Row>
          <Column size={{ xs: 12, sm: 6 }}>
            <label htmlFor="multiplier-kind">
              {translate('user.attrs.multiplierKind')}
            </label>
            <Select
              error={translateError('multiplierKind')}
              id="multiplier-kind"
              onChange={getHandleInputChange('multiplierKind')}
              options={multiplierKindOptions}
              value={values.multiplierKind || 'none'}
            />
          </Column>
        </Row>

        <Checkbox
          error={translateError('termsAndConditionsAccepted')}
          id="terms-and-conditions-accepted"
          label={formatTermsAndConditionsLabel()}
          onChange={getHandleCheckboxChange('termsAndConditionsAccepted')}
          checked={values.termsAndConditionsAccepted || false}
        />
        <Checkbox
          id="newsletter-recipient"
          label={translate('user.attrs.newsletterRecipient')}
          onChange={getHandleCheckboxChange('newsletterRecipient')}
          checked={values.newsletterRecipient || false}
        />
        <SubmitContainer>
          <Button small disabled={isSubmitting}>
            {translate('submit')}
          </Button>
        </SubmitContainer>
      </Grid>
    </form>
  )
}

RegistrationForm.defaultProps = {
  onSubmit: () => {},
}

RegistrationForm.propTypes = {
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  translate: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
}

export default withTranslation({ translations })(RegistrationForm)

import { CLIENT_ID } from 'globalConstants'
import buildFinishUri from 'helpers/buildFinishUri'
import validateEmail from 'utils/validateEmail'
import validatePresence from 'utils/validatePresence'
import validateTelephone from 'utils/validateTelephone'

export function redirectToPasswordSetup({
  email,
  passwordSetupUri,
  redirectUri,
}) {
  const uri = new URL(passwordSetupUri)
  const params = uri.searchParams

  params.append('client_id', CLIENT_ID)
  params.append('redirect_uri', buildFinishUri(redirectUri).toString())
  params.append('user', email)

  window.location.href = uri.toString()
}

export function getValidationErrors(values) {
  const errors = {}
  const {
    additionalTelephone,
    email,
    firstName,
    lastName,
    salutation,
    telephone,
    termsAndConditionsAccepted,
  } = values

  if (!validatePresence(firstName)) errors.firstName = 'missing'
  if (!validatePresence(lastName)) errors.lastName = 'missing'
  if (!validatePresence(salutation)) errors.salutation = 'missing'
  if (additionalTelephone && !validateTelephone(additionalTelephone))
    errors.additionalTelephone = 'invalid'
  if (!termsAndConditionsAccepted) errors.termsAndConditionsAccepted = 'missing'

  if (!validatePresence(email)) errors.email = 'missing'
  else if (!validateEmail(email)) errors.email = 'invalid'

  if (!validatePresence(telephone)) errors.telephone = 'missing'
  else if (!validateTelephone(telephone)) errors.telephone = 'invalid'

  return errors
}

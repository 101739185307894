// Copied from the wizard.

const ALLOWED_EMAIL_USER_CHARS = Object.freeze(
  "abcdefg.hijklmnopqrstuvwxyz!#$%&'*/=?^_+-`{|}~0123456789".split('')
)
const NUMBER_OF_ALLOWED_EMAIL_USER_CHARS = ALLOWED_EMAIL_USER_CHARS.length

function validateEmailStructure(email) {
  return !!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

function validateEmailUser(user) {
  const chars = new Set([...ALLOWED_EMAIL_USER_CHARS, ...user.split('')])

  return (
    user.length &&
    !user.startsWith('.') &&
    chars.size === NUMBER_OF_ALLOWED_EMAIL_USER_CHARS
  )
}

function validateEmailHost(host) {
  return !(
    !host.includes('.') ||
    host.endsWith('-') ||
    host.endsWith('.') ||
    host.includes('..') ||
    host.includes('_') ||
    host.includes('~') ||
    host.includes('—') ||
    host.includes('–')
  )
}

function validateEmail(email) {
  const lowercaseEmail = email.trim().toLowerCase()
  const parts = lowercaseEmail.split('@')
  const [user, host] = parts

  return (
    parts.length === 2 &&
    validateEmailStructure(lowercaseEmail) &&
    validateEmailUser(user) &&
    validateEmailHost(host)
  )
}

export default validateEmail

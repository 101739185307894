import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from '@compeon-os/translated-components'
import { Button, TextInput } from '@compeon-os/components'

import SubmitContainer from 'components/SubmitContainer'

import translations from './EmailField.translations'

function EmailField({ disabled, error, onChange, onSubmit, translate, value }) {
  const [submitted, setSubmitted] = useState(false)

  const handleSubmit = event => {
    event.stopPropagation()
    event.preventDefault()

    setSubmitted(true)
    onSubmit(value.trim().toLowerCase())
  }

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="email">{translate('email')}</label>
      <TextInput
        error={submitted && error}
        id="email"
        onChange={onChange}
        value={value}
      />
      <SubmitContainer>
        <Button small disabled={disabled}>
          {translate('submit')}
        </Button>
      </SubmitContainer>
    </form>
  )
}

EmailField.defaultProps = {
  onChange: () => {},
  onSubmit: () => {},
}

EmailField.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  translate: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

export default withTranslation({ translations })(EmailField)

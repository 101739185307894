const translations = {
  de_DE: {
    description:
      'Nur noch wenige Angaben und Sie können auf COMPEON zugreifen.',
    hotSecretTipLabel: 'Unser Tipp:',
    hotSecretTip: `
      Setzen Sie unten beide Haken und Sie erhalten unseren Newsletter mit
      Fachartikeln, Fallbeispielen, Rechnern und Angeboten rund um das Thema
      Unternehmensfinanzierung
    `,
    errors: {
      submit:
        'Die Registrierung konnte nicht abgeschlossen werden. Bitte versuchen Sie es erneut.',
    },
    title: 'Registrierung',
  },
}

export default translations

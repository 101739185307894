// Copied from the wizard.

function validateTelephone(telephone) {
  if (typeof telephone !== 'string') return false

  const validFirstCharacter =
    telephone.startsWith('0') || telephone.startsWith('+')

  return validFirstCharacter && telephone.length > 5
}

export default validateTelephone

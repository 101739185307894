import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { H3, Paper } from '@compeon-os/components'

import { Footer } from 'components/Layout'
import FooterContent from 'components/FooterContent'

const StyledH3 = styled(H3)`
  margin-top: 50px;
  margin-bottom: 50px;
`

const Container = styled.div`
  max-width: ${props => (props.condensed ? '860px' : '100%')};
  padding: 0 20px;
`

Container.propTypes = {
  condensed: PropTypes.bool,
}

const RouteContainer = ({ children, condensed, title }) => (
  <>
    <Container condensed={condensed}>
      <StyledH3 centered decorated>
        {title}
      </StyledH3>
      <Paper>{children}</Paper>
    </Container>
    <Footer>
      <FooterContent />
    </Footer>
  </>
)

RouteContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  condensed: PropTypes.bool,
  title: PropTypes.string,
}

export default RouteContainer

import styled from 'styled-components'

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

export const PageContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
`

export const Footer = styled.div`
  margin-bottom: 40px;
  margin-top: 40px;
  text-align: center;
`
